/* Notre Solution Styles */
.notre-solution h1 {
    font-size: 60px !important;
    font-weight: 600 !important;
    color: #0CA3FC !important;
    text-align: left !important;
    margin-top: 99px;
}

.notre-solution p {
    font-size: 26px;
    font-weight: 400;
    text-align: left;
    line-height: 48px !important;
}

/* Borne Section Styles */
.borne-section {
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0px 1px 18px 0px #0000001F;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.borne-section:hover {
    box-shadow: 0px 4px 30px 0px #0000001F;
    transform: translateY(-5px);
}

.borne-image {
    height: auto;
    border-width: 3px 3px 0px 0px;
    margin-bottom: 10px;

}
.borne{
    padding: 10px;
}

.borne-title {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    background-color: #0036AF;
    cursor: pointer;
}
.borne-title:hover {
    background-color: #0CA3FC; 
    transform: scale(1.01); 
}

.borne-container{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
   

}

.borne-grid .borne-section {
    flex-basis: calc(33.33% - 20px);
}


.video-container {
    position: relative;
    display: flex;
    justify-content: center; 
    margin-top: 48px !important;
}

.video {
    width: 100%;
    height: auto;
    max-height: 830px;
    object-fit: cover;
}
.conclusion{
    margin-top: 40px;
    margin-bottom: 50px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
}
.conclusion h5{
    font-size: 24px!important;
    font-weight: 600;
}
.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 50px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  
  .play-icon:hover {
    opacity: 0.8;
  }
  
  .video-container video:playing + .play-icon {
    display: none;
  }
/* Responsive for Smaller Screens */
@media(max-width: 1024px) {
    .borne-grid .borne-section {
        flex-basis: calc(50% - 20px); /* 2 columns for medium screens */
    }
}

@media(max-width: 768px) {
    .borne-grid .borne-section {
        flex-basis: 100%; 
    }
    .borne-container {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        background-color: #fff;
        flex-direction: column;
    }
  
}

@media(min-width: 1024px) and (max-width: 1440px) {
    .notre-solution h1 {
        font-size: 45px !important;
    }
    .notre-solution p {
        font-size: 20px !important;
        line-height: 33px !important;
    }
    .video {
        width: 100%;
        height: auto;
        max-height: 830px;
        object-fit: cover;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    .notre-solution h1 {
        font-size: 38px !important;
    }
    .notre-solution p {
        font-size: 20px !important;
        line-height: 33px !important;
    }
}

@media(max-width: 768px) {
    .notre-solution h1 {
        font-size: 36px !important;
    }
    .notre-solution p {
        font-size: 20px !important;
        line-height: 33px !important;
    }
  
}

@media(max-width: 480px) {
    .notre-solution h1 {
        font-size: 36px !important;
        margin-top: 64px;

    }
    .notre-solution p {
        font-size: 18px !important;
        line-height: 27px !important;
    }
 
    .borne-title {
        font-size: 18px;
    }
    .borne-container {
        gap: 0px;
    }
    .conclusion {
        font-size: 16px;
    }
    .conclusion h5 {
        font-size: 22px !important;
    }
    .video-container {
        margin-top: 20px !important;
    }
}
