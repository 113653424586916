.thank-you-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    text-align: center;
    color: #000080; 
}

.content {
    max-width: 600px;
    padding: 20px;
}

.icon {
    margin-bottom: 20px;
}

.thank-you-page h1 {
    font-size: 24px;
    margin-bottom: 18px;
    font-weight: 400 !important;
    color: #000000;
}

.thank-you-page p {
    font-size: 24px;
    margin-bottom: 40px;
    color: #0036AF;
}

.appointment-button {
    background-color: #0036AF; 
    color: white;
    border: none;
    padding: 10px 20px !important;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    border-radius: 2px !important;
}

.appointment-button:hover {
    background-color: #000066; 
}
@media(max-width: 480px){
    .thank-you-page h1 {
        font-size: 22px;
    }
    .thank-you-page p {
        font-size: 22px;
    }
    .appointment-button svg{
        width: 10%;
    }
    .icon svg{
       width: 22%;
    }
    .appointment-button {
        font-size: 16px;
    }

}