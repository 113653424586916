.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    padding: 20px;
  }
  
  .notfound-container h1 {
    color: #0036AF;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .notfound-container p {
    font-size: 26px;
    line-height: 45px;
    font-weight: 400;
    color: #333;
    margin-bottom: 40px;
  }
  
  .notfound-button {
    color: #ffffff;
    background-color: #0036AF;
    padding: 15px 30px;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .notfound-button:hover {
    background-color: #002d8f;
  }
  
  @media(min-width: 1024px) and (max-width: 1440px) {
    .notfound-container h1 {
      font-size: 50px;
    }
  
    .notfound-container p {
      font-size: 24px;
    }
  
    .notfound-button {
      font-size: 18px;
      padding: 12px 25px;
    }
  }
  
  @media(min-width: 768px) and (max-width: 1024px) {
    .notfound-container h1 {
      font-size: 45px;
    }
  
    .notfound-container p {
      font-size: 22px;
    }
  
    .notfound-button {
      font-size: 17px;
      padding: 10px 20px;
    }
  }
  
  @media(max-width: 767px) {
    .notfound-container h1 {
      font-size: 36px;
    }
  
    .notfound-container p {
      font-size: 20px;
    }
  
    .notfound-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  
  @media(max-width: 600px) {
    .notfound-container h1 {
      font-size: 30px;
    }
  
    .notfound-container p {
      font-size: 18px;
    }
  
    .notfound-button {
      font-size: 15px;
      padding: 7px 14px;
    }
  }
  