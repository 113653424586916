.faq-container {
    width: 100%;
    margin: 0 auto;
    margin-top: 70px;
}

.faq-container h2 {
    color: #0036AF;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 5px;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 22px !important;
    font-weight: 500;
    border-left: 5px solid #0CA3FC;
    border-bottom: 1px solid #0CA3FC;
    text-align: left;
    box-shadow: 0px 1px 18px 0px #0000001F;
    min-height: 70px;
    border-radius: 10px;

}

.faq-question.active {
    color: #0036AF;

}

.faq-question:not(.active) {
    color: #000;

}

.faq-icon {
    font-size: 24px;
    color: #0CA3FC;
}

.answer p {
    background-color: #fff;
    color: #000;
    font-size: 16px !important;
    line-height: 28px !important;
    margin-bottom: 0px !important;
}

@media(max-width: 768px) {
    .faq-question{
        font-size: 18px!important;
        text-align: left;
    }
    .faq-container h2{
        line-height: 38px !important;
    }
    .answer p {
        background-color: #fff;
        color: #000;
        font-size: 14px !important;
        line-height: 24px !important;
        margin-bottom: 0px !important;
    }
}

@media(max-width: 480px) {
    .faq-question {
        font-size: 16px !important;
        text-align: left;
    }
    .faq-container {
        margin-top: 40px;
    }
}