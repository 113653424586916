.web-solution-container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .web-solution-title {
    font-size: 60px !important;
    font-weight: 600 !important;
    color: #0CA3FC !important;
    text-align: left !important;
    margin-top: 99px;
  }
  
  .web-solution-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 1px 18px 0px #0000001F;

  }
  
  .web-solution-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .web-solution-text h3 {
    color: #0036AF;
    font-size: 26px !important;
    line-height: 43px !important;
    margin-bottom: 20px;
  }
  
  .web-solution-text ul {
    list-style: none;
    padding: 0;
  }
  
  .web-solution-text li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .web-solution-text li i {
    width: 40px;
    height: 40px;
    background-color: #0086eb;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .web-solution-text li span {
    font-size: 22px;
    font-weight: 400;
    line-height: 45px;
  }
  
  .web-solution-image {
    flex: 1;
  }
  
  .web-solution-image img {
    max-width: 100%;
    border-radius: 8px;
  }
  .web-solution-text img {
    max-width: 18% !important;
}
  .online-services-container {
    padding: 70px 20px;
    background-color: #E6EBF7;
    text-align: center;
    margin-top: 60px;

  }
  
  .online-services-container h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #0036AF;
  
  }
  
  .services-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 1320px;
    margin: 0 auto;
    margin-top: 40px;
  }
  
  .service-card {
    background-color: white;
    border-radius: 8px;
    padding: 26px 18px 35px 18px;
    width: 245px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 1px 18px 0px #0000001F;
    transition: transform 0.3s ease;
    border-bottom: 4px solid #0CA3FC;
  }
  
  .service-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  
  .service-card span {
    font-size: 18px;
    font-weight: 600;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .birthday-reservation-container {
    padding: 115px 20px;
    text-align: center;
    position: relative;
  }
  
  .birthday-reservation-container h2 {
    font-size: 24px;
    font-weight: 600;
    color: #0036AF;
    margin-bottom: 30px;
  }
  
  .services-cards-birthday {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1320px;
    margin: 0 auto;
    gap: 20px;
  }
  
  .service-card-birthday {
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;
    min-width: 400px;
    max-height: 63px;
    box-shadow: 0px 1px 18px 0px #0000001F;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    position: relative;
    border-bottom: 2px solid #0CA3FC;
    gap: 10px;
    text-align: left;
  }
  .card-birthday{
  
    margin-bottom: 50px;

  }
  
  .service-card-birthday img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  
  .service-card-birthday span {
    font-size: 22px;
    font-weight: 400;
  }
  
  .service-card-birthday:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  }

  
  .birthday-reservation-container::before {
    content: '';
    position: absolute;
    bottom: 7%;
   left: 0;
    width: 100%;
    height: 100%;
    z-index: -99999;
    background: url('../../../../images/web/xcv-01.png') no-repeat left top;
 
 
  }
  .birth-img{
    left: 3%;
    position: relative;
    z-index: 99999;
    max-width: 24% !important;
  }
  
  .birthday-reservation-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: -99999;
    height: 100%;
    background: url('../../../../images/web/ballons.png') no-repeat right bottom;
  }
  
  @media(max-width: 1024px){
    .web-solution-title  {
        font-size: 45px !important;
    }

    .services-cards-birthday {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 1320px;
        margin: 0 auto;
        gap: 20px;
    }
    .card-birthday {
        margin-bottom: 10px;
    }
    .birthday-reservation-container h2 {
        font-size: 24px;
        font-weight: 600;
        color: #0036AF;
        margin-bottom: 0px;
        margin-top: 55px;
    }
    .web-solution-text h3 {
        color: #0036AF;
        font-size: 24px !important;
        line-height: 35px !important;
        margin-bottom: 20px;
    }
    .web-solution-text li span {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
  }
@media(max-width: 998px){
    .web-solution-title {
        font-size: 36px !important;
    }
    .web-solution-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 1px 18px 0px #0000001F;
        flex-direction: column;
    }
}
  @media(max-width: 767px){
    .web-solution-title {
        font-size: 36px !important;
        margin-top: 45px;
    }
    .online-services-container {
      padding: 46px 20px;
      margin-top: 34px;
  }
    .services-cards-birthday {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1320px;
        margin: 0 auto;
        gap: 20px;
    }
    .birthday-reservation-container::after {
        content: none;
    }
    .birthday-reservation-container::before {
        bottom: 12%;
    }
    .services-cards-birthday {
        gap: 15px;
    }

  }
  @media (max-width: 576px){
    .service-card-birthday {
        background-color: white;
        border-radius: 10px;
        padding: 20px 30px;
        min-width: 320px;
        max-width: 320px;
    }
    .web-solution-text h3 {
          color: #0036AF;
          font-size: 21px !important;
          line-height: 29px !important;
          margin-bottom: 20px;
      }
      .web-solution-text li span {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
    }

  }
  @media(max-width: 480px){
    .web-solution-title {
        font-size: 36px !important;
        margin-top: 64px;

    }
    .birthday-reservation-container {
      padding: 115px 20px 90px;
    }
    .service-card-birthday span {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
    }
    .services-cards-birthday {
        display: flex;
        justify-content: flex-start;
    }
    .service-card-birthday {
        background-color: white;
        border-radius: 10px;
        padding: 20px 20px;
        min-width: 227px;
        max-width: 227px;
    }
  }