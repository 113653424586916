.carriere-form.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px !important;
    margin: 0 auto;
    margin-top: 40px;

}
.no-offers-message {
    text-align: center;
    color: #0036AF;
    font-size: 26px;
    font-weight: bold;
    margin-top: 25px; 

  }
  
.carriere{
    margin-top: 90px;
}
.carriere-form h1 {
    margin-bottom: 10px;
    font-size: 60px !important;
    font-weight: 600;
    text-align: left;
}

.carriere-form p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 34px;
    text-align: left;
}

.carriere-form .form-group-row {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 38px;
    width: 80%;
}

.form-group-row-p {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 79%;
    flex-direction: column;
    align-items: flex-start;
}

.carriere-form .form-group {
    display: flex;
    flex-direction: column;
    width: 48%;
    align-items: flex-start;
}

.carriere-form label {
    font-size: 20px;
    margin-bottom: 5px;
    color: #0036AF;
    text-align: left;
    font-family: 'InterSemiBold';
}

.carriere-form input,
.carriere-form textarea {
    padding: 10px;
    border-radius: 2px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px 0px #0000001F;
    border: 1px solid #F5F5F5;
}

.carriere-form input::placeholder,
.carriere-form textarea::placeholder {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
}

.carriere-form textarea {
    resize: vertical;
    height: 100px;
}
.carriere-form input[type="file"]{
    display: none;
}
.carriere-form .custom-file{
    padding: 10px;
    border-radius: 2px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px 0px #0000001F;
    /* border: 1px solid #F5F5F5; */
    border: 2px dashed #0CA3FC;
    border-width: 2.5px;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #0CA3FC30;
    flex-direction: column;
    gap: 10px;
}
.carriere-form .custom-file span{
    font-weight: 400;
    font-size: 18px;
    font-family: 'InterRegular';
}
.carriere-form button {
    background: #0036AF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 2px !important;
    cursor: pointer;
    font-size: 1rem;
    display: block;
    margin: 0 0 0 auto;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.carriere-form button:hover {
    background: #0056b3;
}

@media (max-width: 1200px) {
    .carriere-form .form-group-row {
        display: flex;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 38px;
        width: 90%;
    }
}
@media (max-width: 1024px) {
    .carriere-form h1 {
        font-size: 2.5rem; 
    }

    .carriere-form p {
        font-size: 1rem; 
    }

    .carriere-form .form-group-row {
        flex-direction: column;
        width: 100%;
    }

    .carriere-form .form-group {
        width: 100%;
    }
    /* .carriere-form label {
        font-size: 16px;
    } */

    .carriere-form .form-group-row,
    .form-group-row-p {
        width: 100%;
    }
    .carriere-form button {
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .carriere-form h1 {
        font-size: 2rem !important; 
    }

    .carriere-form p {
        font-size: 1rem !important ; 
    }

    .carriere-form {
        padding: 15px !important;
    }
}

@media (max-width: 600px) {
    .carriere-form {
        padding: 10px; 
    }

    .carriere-form h1 {
        font-size: 1.5rem !important; 
    }

    .carriere-form p {
        font-size: 0.9rem !important; 
    }

    .carriere-form .form-group-row {
        flex-direction: column;
    }

    .carriere-form .form-group {
        width: 100%;
    }

    .form-group-row-p {
        width: 100%;
    }
    .carriere-form label {
        font-size: 16px;
    }
    .carriere-form .form-group-row {
        display: flex;
        justify-content: flex-start;
        gap: 24px;
    }
    .carriere-form button {
        font-size: 15px !important;
    }
}
