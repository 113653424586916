.ol-zoom.ol-unselectable.ol-control{
    display: none !important;
}
.ol-rotate.ol-unselectable.ol-control.ol-hidden{
    display: none !important;
}
.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible{
    display: none !important;
}
.ol-viewport.ol-touch{
    border-radius: 10px;
}
.ol-viewport {
    border-radius: 10px;
}