.locations-container.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* max-width: 1530px; */
    margin: 0 auto;
}

.map-container {
    width: 100%;
    max-width: 800px;


}
.loc-icon{
    width: 13px !important;
    height: 19px;
}
.mes-icon{
    width: 13px !important;
    height: 19px;
}

.locations-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.location-card {
    border: 1px solid #ccc;
    padding: 20px 163px 20px 20px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px 0px #0000001F;
    align-items: start;
    gap: 26px;
}

.logoL {
    font-size: 30px;
    margin-bottom: 10px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Location h2 {
    font-family: InterSemiBold;
    font-size: 45px !important;
    font-weight: 600;
    line-height: 70px !important;
    text-align: center !important;
    color: #0036AF;
}

.div {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.location-card img {
    max-width: 100%;
    height: auto;
}
.Location a {
    color: #000000;
    text-decoration: none;

}

@media(min-width: 768px) and (max-width: 1024px)  {
    .Location h2 {
        font-size: 40px !important;
        line-height: 50px !important;
    }
    .div {
        flex-direction: column;
    }

}

@media(max-width: 767px) {

.div {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .Location h2 {
    font-size: 28px !important;
    line-height: 40px !important;
    margin-bottom: 30px !important;
  }
  .Location p {
    font-size: 14px;
    line-height: 20px !important;
  }
.map-container {
    width: 95%;
}
.location-card {
    padding: 20px 20px 20px 20px;
}

}