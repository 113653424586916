.mentions-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .mentions-title {
    font-size:60px;
    margin-bottom: 20px;
    color: #000000;
  }
  
  .mentions-subtitle {
    font-size: 24px !important;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #000000;
  }
  
  .mentions-text {
    font-size: 18px;
    margin-bottom: 10px;
    color: #000000;
  }
  
  @media (max-width: 768px) {
    .mentions-title {
      font-size: 2rem;
    }
  
    .mentions-subtitle {
      font-size: 1.5rem !important;
    }
  
    .mentions-text {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .mentions-title {
      font-size: 1.8rem;
    }
  
    .mentions-subtitle {
      font-size: 1.3rem !important;
    }
  
    .mentions-text {
      font-size: 0.85rem;
    }
  }
  