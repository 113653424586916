.who-are-we h1 {
    font-size: 60px;
    font-weight: 600;
    color: #0CA3FC;
    text-align: left !important;
    margin-top: 125px;

}
.who-are-we.container-fluid{
    position: relative;
}

.qui-sommes-nous p {
    font-size: 26px;
    font-weight: 400;
    text-align: left;
    line-height: 48px !important;
}
.who-are-we h2 {
    font-size: 2.5rem;
    color: #0036AF;
    font-weight: 600;
    text-align: left;
  }
.nos-valeurs{
   margin-top: 80px;
}
.valeurs{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
.val{
    box-shadow: 0px 1px 5px 0px #0000001F;
    border-radius: 10px;
    padding: 30px 20px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

}
.val-text{
    
font-size: 22px !important;
font-weight: 500 !important;
margin-bottom: 0;

}
.rectangle-who {
    margin-top: 80px;
    width: 100vw;
    height: 214px;
    background-color: #0036AF;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13%;
  }
  .rec {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}
.rec1 {
    font-size: 60px;

   
}
.rec2 {
    font-size: 26px;
  
    
}

.team{
    margin-top: 80px;
    box-shadow: 0px 0px 5px 0px #0036AF80;
    max-width: 1320px;
    border-radius: 10px;
    padding: 30px;
    text-align: left;
    margin-bottom: 80px;
    position: relative;

}

  .background-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px; /* Adjust width as needed */
    height: 100%;
    background-image: url('../../../../public/images/Exclude\ \(1\).png'); /* Path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* Ensure it's behind the content */
  }
.button-who {
    background-color: #0036AF;
    color: white;
    border: none;
    padding: 20px 22px 20px 20px !important;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    border-radius: 6px !important;
}
@media(min-width: 1024px) and (max-width: 1440px) {
    .who-are-we h1 {
        font-size: 45px;
        font-weight: 600;
        color: #0CA3FC;
        text-align: left !important;
        margin-top: 77px;
    }
    .qui-sommes-nous p{
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        line-height: 33px !important;
    }
    .val {
        box-shadow: 0px 1px 5px 0px #0000001F;
        border-radius: 10px;
        padding: 20px 20px;
        min-width: 190px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .val-image{
        width: 55%;
    }
    .val-text{
        font-size: 17px !important;
    }
    .rectangle-who {
        margin-top: 80px;
        width: 100vw;
        height: 200px;
    }
    .rec1 {
        font-size: 45px;
    }
    .rec2 {
        font-size: 22px;
    }
    .team {
        margin-top: 80px;
        box-shadow: 0px 0px 5px 0px #0036AF80;
        max-width: 1320px;
        border-radius: 10px;
        padding: 26px;
        text-align: left;
        margin-bottom: 80px;
    }
    .button-who {
        background-color: #0036AF;
        color: white;
        border: none;
        padding: 17px 22px 17px 10px !important;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px !important;
    }
    .button-who svg{
        width: 9%;
    }
}
@media(min-width: 768px) and (max-width: 1024px) {
    .who-are-we h1 {
        font-size: 38px;
        font-weight: 600;
        color: #0CA3FC;
        text-align: left !important;
        margin-top: 77px;
    }
    .qui-sommes-nous p {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        line-height: 33px !important;
    }
    .val {
        box-shadow: 0px 1px 5px 0px #0000001F;
        border-radius: 10px;
        padding: 20px 20px;
        min-width: 190px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .val-image{
        width: 55%;
    }
    .val-text{
        font-size: 17px !important;
    }
    .rectangle-who {
        margin-top: 80px;
        width: 100vw;
        height: 200px;
    }
    .rec1 {
        font-size: 45px;
    }
    .rec2 {
        font-size: 22px;
    }
    .team {
        margin-top: 80px;
        box-shadow: 0px 0px 5px 0px #0036AF80;
        max-width: 1320px;
        border-radius: 10px;
        padding: 26px;
        text-align: left;
        margin-bottom: 80px;
    }
    .button-who {
        background-color: #0036AF;
        color: white;
        border: none;
        padding: 17px 22px 17px 10px !important;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px !important;
    }
    .button-who svg{
        width: 9%;
    }

}

@media(max-width: 768px) {
    .who-are-we h1 {
        font-size: 36px;
        font-weight: 600;
        color: #0CA3FC;
        text-align: left !important;
        margin-top: 77px;
    }
    .qui-sommes-nous p {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        line-height: 33px !important;
    }
    .val {
        box-shadow: 0px 1px 5px 0px #0000001F;
        border-radius: 10px;
        padding: 20px 20px;
        min-width: 190px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .val-image{
        width: 55%;
    }
    .val-text{
        font-size: 17px !important;
    }
    .rectangle-who {
        margin-top: 80px;
        width: 100vw;
        height: 200px;
    }
    .rec1 {
        font-size: 35px;
    }
    .rec2 {
        font-size: 18px;
    }
    .team {
        margin-top: 80px;
        box-shadow: 0px 0px 5px 0px #0036AF80;
        max-width: 1320px;
        border-radius: 10px;
        padding: 26px;
        text-align: left;
        margin-bottom: 80px;
    }
    .button-who {
        background-color: #0036AF;
        color: white;
        border: none;
        padding: 17px 22px 17px 10px !important;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px !important;
    }
    .button-who svg{
        width: 9%;
    }
    .nos-valeurs {
        margin-top: 26px;
    }

}
@media(max-width: 480px) {
    .rec1 {
        font-size: 24px;
    }
    .rec2 {
        font-size: 14px;
    }
    .rectangle-who {
        margin-top: 80px;
        width: 100vw;
        height: 150px;
    }
    .button-who {
        background-color: #0036AF;
        color: white;
        border: none;
        padding: 14px 20px 14px 8px !important;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        font-size: 17px;
        font-weight: 500;
        border-radius: 6px !important;
    }
    .qui-sommes-nous p    {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 27px !important;
    }
    .who-are-we h1 {
        font-size: 36px;
        font-weight: 600;
        color: #0CA3FC;
        text-align: left !important;
        margin-top: 77px;
    }

}