.partner-slider-container {
  width: 100vw;
  
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;
    left: 0;
    right: 0;
}


.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden{
  padding: 24px;
}
.swiper-slide.swiper-slide-active{
  margin-right: 0 !important;
}
/* 
.partner-slider-container img {
  width: 100%;
  height: auto;
  object-fit: cover; 
} */
/* .swiper-button-prev, 
.swiper-button-next{
  display: none !important;
} */