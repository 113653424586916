.qr-solution-container {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .qr-solution-title {
    font-size: 60px !important;
    font-weight: 600 !important;
    color: #0CA3FC !important;
    text-align: left !important;
    margin-top: 99px;
  }
  
  .qr-solution-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 20px 20px 20px 35px;

    box-shadow: 0px 1px 18px 0px #0000001F;

  }
  
  .qr-solution-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .qr-solution-text h3 {
    color: #0036AF;
    font-size: 26px !important;
    line-height: 43px !important;
    margin-bottom: 20px;
  }
  
  .qr-solution-text ul {
    list-style: none;
    padding: 0;
  }
  
  .qr-solution-text li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .qr-solution-text li i {
    width: 40px;
    height: 40px;
    background-color: #0086eb;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .qr-solution-text li span {
    font-size: 22px;
    font-weight: 400;
    line-height: 45px;
  }
  .qr-solution-text img{
    max-width: 18% !important;
  }
  
  .qr-solution-image {
    flex: 1;
    width: 100% !important;
  }
  
  .qr-solution-image img {
    max-width: 100%;
    border-radius: 8px;
    width: 100%;
  }
  .box{
    background: #E6EBF7;
    display: flex;
    justify-content: center;
    height: 470px;
    margin-top: 40px;
    box-shadow: 0px 1px 5px 0px #0000001F;
  }
  .box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 20px;

  }
.box-content h2{
    font-size: 31px !important;
    text-align: center;
    color: #0036AF;
    line-height: 54px !important;
}
  @media(max-width: 1024px){
    .qr-solution-title  {
        font-size: 45px !important;
    }

    .qr-solution-text h3 {
        color: #0036AF;
        font-size: 24px !important;
        line-height: 35px !important;
        margin-bottom: 20px;
    }
    .qr-solution-text li span {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
}

@media(max-width: 998px){
    .qr-solution-title {
        font-size: 36px !important;
    }
    .qr-solution-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 1px 18px 0px #0000001F;
        flex-direction: column;
    }
}
@media(max-width: 992px){
    .box-content h2 {
        font-size: 28px !important;
        text-align: center !important;
        color: #0036AF;
        line-height: 43px !important;
    }
}
@media(max-width: 767px){
    .qr-solution-title {
        font-size: 36px !important;
        margin-top: 45px;
    }
    .box-content h2 {
        font-size: 24px !important;
        text-align: center !important;
        color: #0036AF;
        line-height: 36px !important;
    }
}
@media(max-width: 576PX){
    .box-content h2 {
        font-size: 22px !important;
        text-align: center !important;
        color: #0036AF;
        line-height: 36px !important;
        padding: 0px 20px;
    }
    .qr-solution-text li span {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
    }
    .qr-solution-text h3 {
        color: #0036AF;
        font-size: 21px !important;
        line-height: 29px !important;
        margin-bottom: 20px;
    }
}
@media(max-width: 480px){
    .qr-solution-title {
        font-size: 36px !important;
        margin-top: 64px;

    }
 
}