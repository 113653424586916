.navbar {
  z-index: 100;
  /* position: fixed; */
  /* top: 0; */
  background-color: #FFFFFF;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000029;

}

.nav-logo {
  padding: 0;
}
.link-demo{
  padding-bottom: 18px;
}
.langue>.dropdown-menu {
  min-width: 5rem !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #20ACE3;
  box-shadow: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #20ACE3;
  background-color: transparent;
}

.dropdown-item,
.nav-link {
  color: #17406F;
  font-weight: 600;
}

.langue > .dropdown-item{
  padding: .25rem 0.5rem !important;
}

.dropdown-toggle:hover {
  color: #20ACE3 !important;
}

.title_without_dropdown:hover {
  color: #20ACE3 !important;
}

.dropdown-toggle::after {
  background: url(/public/images/navbar/down-arrow-svgrepo-com.svg);
  content: ".";
  color: transparent;
  background-repeat: no-repeat;
  width: 22px;
  position: relative;
  top: 10px;
  left: 5px;
  right: 5px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(4,62,112, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 38px;
  margin-right: -10px;
  margin-bottom: 0px;
}

.navbar-toggler>.close {
  display: inline;
  margin-right: -5px !important;
  position: relative;
  top: 0px !important;
}

.navbar-toggler.collapsed>.close,
.navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
  display: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.nav-services {
  display: flex;
  white-space: nowrap;
  width: auto;
  padding-left: 8px;
  padding-right: 0 !important;
}

.nav-services .nav-link {
  padding-right: 0;
}

@media (max-width: 592px) {
  .navbar {
    padding: 2px 25px 0px 35px !important;
  }

  .navbar-collapse {
    padding: 0 30px !important;
  }
}

@media (min-width: 992px) {
  .buttonNav {
    margin-top: 23px !important;
  }

  .navbar-collapse {
    padding-bottom: 10px;
    place-content: end;
  }

  .navbar-nav {
    padding-left: auto !important;
    position: absolute !important;
  }

  .menu {
    padding: 10px;
    padding-top: 25px;
  }

  .dropdown-toggle {
    padding: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }

  .with_dropdown {
    margin-top: 8px;
    max-height: 61px;
    padding: 25.2px 0 50px;

  }

  .without-dropdown {
    margin-top: 17px;
    max-height: 71px;
    padding: 25px 0 50px;
  }

  .title_without_dropdown {
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu:nth-child(5) {
    padding-left: 15px;
    padding-right: 31px !important;
  }
}

.menu {
  padding-right: 15px;
  padding-left: 15px;
}

.langue>a {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 44.6px;
}

@media (min-width:993px) {
  .navbar {
    padding: 20px 70px 20px;
  }

  .nav-item.dropdown:hover .dropdown-menu.show {
    display: block;
    margin-top: -5px;
  }

  .dropdown-toggle {
    z-index: 10;
  }

  .dropdown-menu {
    top: 75px;
    border: none;
    padding: 11px 20px 14px 15px;
    box-shadow: 0px 0px 8px rgba(23, 64, 111, .19);
    animation-name: animation;
    animation-duration: 1s;
  }

  @keyframes animation {
    0% {
      top: 90px;
      opacity: 80%;
    }

    100% {
      top: 75px;
      opacity: 100%;
    }
  }

  .buttonNav {
    position: relative !important;
  }

  .dropdown-menu.show .nav-link {
    padding-bottom: 8px;
  }
}

@media (max-width: 1400px) {
  .menu {
    padding-right: 10px;
    padding-left: 10px;
  }

  .langue {
    padding-left: 5;
  }
}

@media (max-width: 1360px) {
  .menu {
    padding-right: 5px;
    padding-left: 5px;
  }

  .langue {
    padding-left: 5;
  }
}

@media (max-width: 1340px) {
  .menu {
    padding-right: 0px;
    padding-left: 0px;
  }

  .langue {
    padding-left: 5;
  }

  .navbar-nav .nav-link {
    padding-left: 0;
  }
}

@media (max-width: 1300px) {
  .navbar-nav .d-flex .nav-link {
    padding-left: 0;
  }

  .langue {
    padding-left: 5px;
  }
}

@media (max-width: 1270px) {
  .menu:nth-child(5) {
    padding-right: 0px;
  }
}

@media (max-width: 1245px) and (min-width: 992px) {
  .dropdown-toggle::after {
    left: -4px;
  }

  .title_without_dropdown {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 12px;
        padding-right: 12px;
  }

  .menu:nth-child(3) {
    padding-right: 10px;
  }

  .menu:nth-child(5) {
    padding-right: 15px;
  }

}

@media (max-width: 1190px) and (min-width:992px) {
  .navbar {

      padding: 22px 30px 22px 30px;
  
  }

  .menu {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1090px) {
  .menu {
    padding-right: 1px;
    padding-left: 2px;
  }

  .title_without_dropdown {
    padding-right: 1px;
    padding-left: 2px;
  }

  .menu:nth-child(5) {
    padding-right: 5px;
  }
}

@media (max-width: 1060px) {
  .logo {
      padding-top: 5px;
      width: 170px !important;
  }
 

  /* .buttonNav {
    width: 170px;
    padding: 10px 0 !important;
  } */
}

@media (max-width: 1000px) and (min-width:992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 3px;
    padding-left: 3px;
  }
}
.button {
  font-size: 16px;
  background-color: #0CA3FC;
  border: none;
  will-change: transform;
  transition: .3s ease-in-out;
  transition-delay: .35s;
  overflow: hidden;
  display: inline-block;
  border-radius: 6px !important;
  box-shadow: 0px 3px 4px 0px #0000001F;
  padding: 0;
}

@media (max-width: 1015px) {
  .menu:nth-child(5) .title_without_dropdown {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .navbar {
    background-color: white;
    padding: 0px 40px 5px 50px;
    height: 67px;
  }

  .navbar-ar {
    padding: 0px 50px 5px 20px;
  }

  .dropdown-toggle::after {
    transition: transform 750ms;
    transform: rotate(-90deg);
  }

  .show>.dropdown-toggle::after {
    transform: rotate(0);
  }

  .div-langue {
    justify-content: space-between;
  }

  .logo {
    padding-top: 5px;
    width: 165px !important;
  }

  .navbar-collapse {
    background-color: white;
    position: absolute;
    right: 0;
    top: 58px;
    width: 100%;
    padding: 0 75px;
  }

  .navbar-ar .navbar-collapse{
    padding: 0 60px 0 50px;
  }

  .button {
    margin-bottom: 20px;
  }

  .nav-item.dropdown .dropdown-menu {
    top: 45px;
    border: 0;
    padding: 10px 20px 0px 0px;
    width: auto !important;
    box-shadow: none !important;
  }

  .nav-item.dropdown .dropdown-menu .dropdown-item {
    border-top: 1px dashed #cecece;
    padding: 10px 0 10px 10px;
  }

  .menu_mobile-ar .dropdown-menu .dropdown-item {
    text-align: right;
  }

  .nav-services {
    padding-top: 8px;
    padding-left: 16px;
    display: flex;
    border-top: 1px dashed #cecece;
  }

  .servises-mobile .dropdown-menu {
    margin-left: -120px;
  }

  .menu {
    padding: 10px 0 10px 0;
    border-top: 1px dashed #cecece;
  }

  .menu .nav-link:nth-child(1) {
    padding: 0;
  }

  .dropdown-toggle::after {
    position: absolute;
    width: 30px;
    top: 6px;
    right: 0;
    left: auto;
  }

  .langue-ar .dropdown-toggle::after {
    margin-left: 0;
    right: auto;
    left: 0 !important;
  }


  .menu_mobile-ar .dropdown-toggle::after {
    right: auto;
    left: 0;
  }

  .dropdown-toggle:focus {
    color: #20ACE3 !important;
  }

  .dropdown-menu {
    animation-name: test;
    animation-duration: 5s;
    z-index: -1;
    overflow: hidden;
  }


  @keyframes test {
    from {
      opacity: 90%;
      transform: translateX(40px);
    }

    to {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}

@media (max-width:592px) {
  .navbar {
    padding: 0px 6px 5px 30px !important;
  }

  .navbar-ar {
    padding: 0px 30px 5px 6px !important;
  }

  .mx-2.close>img {
    width: 22px;
  }

  .dropdown-toggle::after {
    width: 26px !important;
  }
}

.backgroundSizeAndColor {
  background-color: #FFFFFF;
  height: 70px;
}



.logoBackground {
  padding-top: 10px;
  width: 110px !important;
}

.menu {
  font-weight: 500;
  font-size: 18px;
  color: #17406F;
}

.menu:hover {
  color: #20ACE3;
}

.logo {
  width: 170px;
}

.button {
  font-size: 18px;
  background-color:#0CA3FC;
  border: none;
  will-change: transform;
  transition: .3s ease-in-out;
  transition-delay: .35s;
  overflow: hidden;
  display: inline-block;
  border-radius: 6px !important;
  box-shadow: 0px 3px 4px 0px #0000001F;
  padding: 11px 13px 11px 13px !important;

}

.button::before {
  transform: translate(-120%, -50%) translateZ(0);
  display: block;
  content: '';
  background: #17406F;
  position: absolute;
  width: 200%;
  height: 500%;
  border-radius: 100%;
  transition: .36s cubic-bezier(0.4, 0.0, 1, 1);
}

.pseudo-text {
  display: inline;
  position: relative;
  font-weight: 600;
  transition: .25s ease-in;
  transition-delay: .1s;
}

.button:hover .pseudo-text {
  color: white;
}

.button:hover::before {
  transform: translate(-45%, -34%) translateZ(0);
}

.button-ar:hover::before {
  transform: translate(45%, -34%) translateZ(0);
}

.button:hover {
  background-color: #20ACE3;
}

.navbar-light .navbar-nav .nav-link {
  color: #17406F;
}

.menus {
  height: 34px;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #17406F;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #17406F;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .show>.nav-link {
  color: #000000;
}

.navbar-light .navbar-nav .show>.nav-link:hover {
  color: #17406F;
}

.servises-web.nav-item {
  text-decoration: none;
  padding-top: 0;
}

.servises-web .nav-link {
  padding: 4px 15px 4px 0px !important;
}

.servises-web {
  padding-right: 80px;
}

.servises-web .dropdown-menu {
  margin-left: 93px;
  margin-top: -43px !important;
}

.servises-mobile.nav-item {
  text-decoration: none;
  padding-top: 0;
}

.servises-mobile .nav-link {
  padding: 4px 15px 4px 7px !important;
}

.servises-mobile .dropdown-menu {
  margin-top: -7px !important;
}