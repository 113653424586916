.allFooter {
  margin-top: auto;
}

.footer {
  background-color: #FFFFFF;
  color: #000000 !important;
  font-size: 16px;
  padding: 30px 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px #0036AF80;

}
.footer > div {
  flex: 1 auto;

}
.footer ul li {
  margin-bottom: 5px;
}
.contacter ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.half-underline{
  position: relative;
  display: inline-block;
}
.half-underline::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 50%;
  border-bottom: 2px solid #0036AF;
}

.logoFooter {
  padding-bottom: 27px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 3px;
  font-family: InterSemiBold;
  color: #0036AF;
}

.description {
  width: 23%;
  min-width: 312px;
  padding-right: 38px;
}

.description-ar{
  padding-right: 0;
  padding-left: 50px;
}

.description p {
  padding-top: 3px;
}

.services {
  width: 30%;
  min-width: 383px;
 
}
.service {
  display: flex;
  gap: 20px;
}

.solution-column ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.contacter {
  
  min-width: 212px;
  
}
.services-contact{
  padding-left: 55px;
}

ul.UlContacter {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120%;
}

ul.UlContacter li {
  padding-bottom: 8px;
}

.link {
  text-decoration: none;
  color: #000000;
}

.aLink {
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}

.contacterImg {
  margin-right: 10px;
  width: 16px;
}

.global {
  width: 26px;
  margin-left: -3px;
  margin-right: 16px;
}

ul.UlContacter-ar li .global{
  margin-left: 16px;
  margin-right: -3px;
}

.UlContacter-ar .contacterImg{
  margin-right: 0;
  margin-left: 20px;
}

.LinkGlobal{
  text-decoration: underline;
}

.seconde {
  margin-right: 20px;
  width: 18px;
  margin-left: 38px;
}

.UlContacter-ar .seconde{
  margin-right: 38px;
  margin-left: 20px;
}

.partenaires {
  width: 18%;
  min-width: 279px;
  ;
}

.partenaire {
  margin-right: 5px;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 14px;
  padding-left: 0;
  
}

.partenaire-ar{
  margin-right: 0;
  margin-left: 16px;
}

.group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
}

.copyright {
  height: 40px;
  font-size: 14px;
  background-color: #0036AF;
  align-items: center;
  color: white;
  display: flex;
  justify-content: center;
  
}

.copyright >bdi >span {
  padding-right: 10px;
}

.copyright a {
  text-decoration: none;
  color: white;
}

.droits-dauteur {
  padding: 0 12px;
}

/* @media (min-width:1323px) {
  .footer {
    justify-content: space-between;
  }
} */

@media (max-width:1204px) and (min-width:769px) {
  .description {
    width: 50% !important;
  }

  .services {
    width: 50% !important;
  }

  .contacter {
    width: 50% !important;
  }

  .partenaires {
    width: 50% !important;
  }

}
@media (max-width:1326px) and (min-width:1200px) {
  .footer{
    flex-wrap: wrap;
  }
}
@media (max-width: 919px) {
  .contacter {
    padding-top: 30px;
  }

}

@media (min-width:770px) {
  .services {
    padding-top: 22px;
  }

  .contacter {
    padding-top: 22px;
  }

  .partenaires {
    padding-top: 22px;
  }

}

@media (min-width:769px) {
  .hideFooter {
    display: none;
  }
}

@media (max-width:768px) {
  .footer {
    flex-direction: column;
    gap: 0px;
  }

  .hide {
    display: none !important;
    transition: all 0.3s cubic-bezier(0.42, 0, 1, 1);
  }

  .show {
    display: block !important;
    transition: all 1s cubic-bezier(0.83, 0.67, 0.17, 0.67) !important;
  }

  .buttonFooter {
    display: flex;
    cursor: pointer;
    width: 100% !important;
  }

  .description {
    padding: 0px !important;
    padding-bottom: 15px !important;
  }

  .services {
    width: auto !important;

  }

  .contacter {
    width: auto !important;
    margin-right: 0px !important;
  }

  .partenaires {
    width: 100% !important;
    flex-direction: row !important;

  }

  .title {
    padding: 0 !important;
    padding-bottom: 0 !important;
    margin: 0;
    font-size: 20px;
  }

  .service li {
    /* padding-left: 20px; */
    padding-top: 10px;
    padding-bottom: 0 !important;
  }

  .liImg {
    display: none;
  }

  .contacter {
    padding-top: 0;
  }

  .UlContacter li {
    padding-top: 10px;
    padding-bottom: 0 !important;
  }

  .groupShow {
    padding-top: 15px !important;
  }

  .grouphide {
    padding: 0 !important;
  }
}

@media (max-width: 619px) {
  .partenaire {
    margin-top: 10px;
  }
}

@media (max-width: 425px) {
  .footer {
    padding: 30px 37px;
  }

  .partenaire {
    margin-top: 20px;
  }
}
@media (min-width: 1326px) {
  .footer{
    flex-wrap: nowrap;
  } 

}
@media (max-width: 1618px) {
  .footer{
    font-size: 14px;
   
  }
}
@media (max-width: 821px) {
  .services-contact {
    padding-left: 0;
  }
}