.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    text-align: center;
    color: #FF4D4D;
}

.content {
    max-width: 600px;
    padding: 20px;
}

.icon {
    margin-bottom: 20px;
}

.error-page h1 {
    font-size: 24px;
    margin-bottom: 18px;
    font-weight: 400 !important;
    color: #FF4D4D;
}

.error-page p {
    font-size: 24px;
    margin-bottom: 40px;
    color: #FF4D4D;
}

.retry-button {
    background-color: #FF4D4D;
    color: white;
    border: none;
    padding: 10px 20px !important;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    border-radius: 2px !important;
}

.retry-button:hover {
    background-color: #CC0000;
}

@media(max-width: 480px){
    .error-page h1 {
        font-size: 22px;
    }
    .error-page p {
        font-size: 22px;
    }
    .retry-button {
        font-size: 16px;
    }
}
