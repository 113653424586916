.borne-solution-container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 20px;
}

.web-solution-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
  color: #0072ce;
}

.time-container {
  position: relative;
  padding-left: 40px;
}

.time-item {
  position: relative;
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

/* Ligne verticale */
.time-container::before {
  content: '';
  position: absolute;
  left: 0.6rem;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #0036AF;
}

/* Point sur la ligne */
.time-item::before {
  content: '';
  position: absolute;
  left: -2.2rem;
  top: 50%;
  width: 12px;
  height: 12px;
  background-color: #0036AF;
  border-radius: 50%;
}

.time-item .time-image {
  margin-right: 20px;
}

.time-item .time-image img {

  background: #ffffff;
  /* width: 150px; */
  /* height: 150px; */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 1px 18px 0px #0000001F;
  padding: 6px;
}


.time-item .time-content {
  max-width: 771px;
  font-size: 18px;

}

.time-content p {
  line-height: 34px !important;
}

@media(max-width: 992px) {
  .time-item .time-image {
    margin-right: 10px;
 
  }

  .time-item .time-content {
    max-width: 600px;
    font-size: 15px;
  }

  .time-content p {
    line-height: 28px !important;
  }
}
@media(max-width: 767px) {
  .time-item {
    position: relative;
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
  .time-item .time-image {
    margin-right: 0px;
    margin-bottom: 20px;
}
.time-item .time-image img {
  height: auto;
  max-width: 100%;
}
}
@media(max-width: 480px){
  .time-item .time-content {
    max-width: 600px;
    font-size: 14px;
}
.time-item .time-content p {
 line-height: 25px !important;
}
}