.timeline-container {
  margin: 0 auto;
  margin-top: 80px;
}

.timeline-container h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: #0036AF;
  font-weight: 600;
  text-align: left;
}

.timeline {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12rem;
  padding: 2rem 0;
  margin-bottom: 12rem;
}

.timeline-event {
  position: relative;
  width:  20%;
  text-align: center;
}

.timeline-info {
  margin-bottom: 1rem;
}

.timeline-box {
  background: #fff;
  box-shadow: 0px 1px 18px 0px #0000001F;
  min-height: 154px;
  max-width: 300px;
  border-radius: 8px;
  padding: 1.5rem;
  position: relative;
  z-index: 2;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.top{
  top:9rem;
  border-top: 4px solid #0CA3FC
}
.bottom{
  bottom: 9rem;
  border-bottom: 4px solid #0CA3FC
}

.timeline-event:nth-child(odd) .timeline-info {
  position: absolute;
  bottom: 7rem; 
  left: 50%;
  transform: translateX(-50%);
}

.timeline-event:nth-child(even) .timeline-info {
  position: absolute;
  top: 7rem; 
  left: 50%;
  transform: translateX(-50%);
}

.timeline-date {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0CA3FC;
  
}

.timeline-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.timeline-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background: #0036AF;
  z-index: 1;
}

.timeline-point {
  width: 1.25rem;
  height: 1.25rem;
  background: #0036AF;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  z-index: 3;
}

.timeline-event:nth-child(odd) .timeline-point {
  transform: translate(-50%, -50%);
}

.timeline-event:nth-child(even) .timeline-point {
  transform: translate(-50%, -50%);
}

/* @media(min-width: 1024px) and (max-width: 1440px) {
  .timeline-container h2 {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: #0036AF;
    font-weight: 600;
    text-align: left;
}
.timeline {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 7rem;
    padding: 2rem 0;
    margin-bottom: 10rem;
}
.timeline-event {
    position: relative;
    width: 25%;
    text-align: center;
}
.timeline-event:nth-child(odd) .timeline-info {
    position: absolute;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
}
.top {
    top: 8rem;
    border-top: 4px solid #0CA3FC;
}
.timeline-box {
    background: #fff;
    box-shadow: 0px 1px 18px 0px #0000001F;
    min-height: 200px;
    max-width: 300px;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bottom {
    bottom: 8rem;
    border-bottom: 4px solid #0CA3FC;
}
.timeline-content {
  font-size: 16px;
  line-height: 1.5rem;
  color: #333;
}
} */
@media(min-width: 992px) and (max-width: 1199px) {
  .timeline-event {
    position: relative;
    width: 29%;
    text-align: center;
}
}

@media(min-width: 768px) and (max-width: 991px) {
  .timeline-event {
    position: relative;
    width: 32%;
    text-align: center;
}
}
/* Responsive for screens between 1024px and 768px */
/* @media(min-width: 768px) and (max-width: 1024px) {
  .timeline {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }

  .timeline:before {
    top: 0;
    left: 50%;
    width: 0.25rem;
    height: 100%;
    background: #0036AF;
    transform: translateX(-50%);
  }

  .timeline-event {
    width: 100%;
    margin-bottom: 4rem;
    position: relative;
  }

  .timeline-box {
    background: #fff;
    box-shadow: 0px 1px 18px 0px #0000001F;
    max-width: 300px;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
  }

  .timeline-event:nth-child(odd) .timeline-box {
    margin-left: 30px;
    top: 50%;
  }

  .timeline-event:nth-child(even) .timeline-box {
    margin-left: calc(50% + 50px);
    transform: translateY(72%);
  }

  .timeline-info {
    text-align: center;
    margin-bottom: 10px;
    position: absolute !important;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .timeline-date,
  .timeline-title {
    text-align: center;
  }

  .timeline-point {
    width: 1.25rem;
    height: 1.25rem;
    background: #0036AF;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
} */
@media (max-width: 767px) {
  .timeline {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }

  .timeline:before {
    display: none; 
  }

  .timeline-event {
    width: 100%;
    margin-bottom: 4rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .timeline-info {
    text-align: center;
    margin-bottom: 10px;
    position: relative !important;
    top: 0 !important; 
    left: 0 !important; 
    transform: none !important; 
    order: -1;
  }

  .timeline-box {
    background: #fff;
    box-shadow: 0px 1px 18px 0px #0000001F;
    max-width: 300px;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
    margin: 0 auto; 
    top: 0;
    bottom: 0;
  }

  .timeline-point {
    display: none; 
  }
  .bottom {
    border-top: 4px solid #0CA3FC;
    border-bottom: none;
  }
}
