.banner {
  height: 720px;
  background-size: cover;
  background-position: right center;
  width: 100%;
  background-image: url('../../../../public/images/banner.png');
}

.container {
 
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.container-fluid {
  text-align: center;
  align-items: center;
}

.container h1 {
  /* font-size: 2em; */
  margin-bottom: 20px;

}

.sol {
  color: #0036AF;
  font-weight: 600;
  line-height: 60px;
  font-size: 45px !important;
}

.pTitle {
  margin-bottom: 40px;
  font-size: 26px;
  line-height: 45px !important;
  font-weight: 400;
}

.special {
  font-weight: 500 !important;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 90px !important;
  max-width: 1520px;
  margin: 0 auto;

}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #0000001F;
  padding: 20px;
  width: 490px;
  text-align: left;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 440px;
}

.row.custom-row {
  justify-content: center;

}

.card.expanded {
  max-height: 500px;
}

.card h3 {
  margin-top: 0;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 33px !important;
}

.card p {
  font-size: 1em;
  margin-bottom: 0 !important;
}

.card img {
  width: 56px;
  height: 56px;
  margin-bottom: 10px;
}

.card-header {
  display: flex;
  align-items: center;
}

.card button {
  color: #0036AF;
  background: none;
  border: none;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  padding: 0 !important;
  text-align: left;
}

.Location p {
  margin: auto;
}

.card button:hover {
  text-decoration: underline;
}

.rectangle {
  width: 100vw;
  height: 164px;
  background-color: #0036AF;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rectangle p {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  line-height: 70px !important;
}

.activities-slider {
  font-size: 56px;
  color: #0036AF;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 90px;
  position: relative;
  width: 100vw; 
  overflow: hidden; 
  white-space: nowrap;
  box-sizing: border-box; 
}

.activities-slider .standard {
  font-weight: 600;
}

.activities-slider .highlight {
  font-weight: bold;
  color: white;
  text-shadow: 2px 1px 6px #0036AF;
}

.activities-slider span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #0036AF;
  left: 0;
  bottom: -3px;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.activities-slider span:hover::after {
  transform: scaleX(1);
}


.activities-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.activities {
  display: flex;
  width: 200%; 
  animation: scroll 20s linear infinite;
}

.activity-item {
  display: flex;
  align-items: center;
}

.activities span {
  padding: 0 15px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}


.professional-equipment {
  text-align: center;
  box-shadow: 0px 0px 5px 0px #0036AF80;
  border-radius: 10px;
  padding: 50px 30px !important;
  max-width: 1530px;
  margin: 0 auto;
  margin-bottom: 90px;

}

.professional-equipment h2 {
  color: #0036AF;
  font-size: 45px !important;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: center !important;
}

.professional-equipment p {
  font-size: 26px;
  line-height: 45px !important;
  margin-bottom: 40px;
  font-weight: 400;

}

.professional-equipment p span {
  font-weight: 500 !important;
  font-family: InterSemiBold;
}

.equipment-cards {
  display: flex;
  justify-content: center;
  gap: 20px;

}

.equipment-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  width: 428px;
  box-shadow: 0px 1px 4px 0px #0000001F;

}

.equipment-card img {
  max-width: 100%;
  position: relative;

}
.equipment-card .eq1{
  left: 22%;
}
.equipment-card .eq2{
  left: 16%;
}

.equipment-card h3 {
  color: #0036AF;
  font-size: 22px;
  margin-bottom: 10px;
}

.equipment-card ul {
  list-style-type: none;
  padding-left: 2%;
}

.equipment-card li::before {
  content: '•';
  color: #0036AF;
  font-size: 20px;
  position: absolute;
  right: 100%;
  line-height: 1;
}

.equipment-card li {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 5px;
}

.rectangle-2 {
  max-width: 1530px;
  padding: 40px !important;
  background-color: #0036AF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 90px;
}

.rectangle-2 p {
  
  font-size: 32px;
  font-weight: 600;
  line-height: 71px !important;
  text-align: center;
  color: #5AC2FF;

}
b, strong {
  font-weight: 500 !important;
}
.rectangle-2 span {
  font-weight: 400;
  color: #fff;
}

.locHome {
  margin-bottom: 90px;
}

@media(min-width: 1024px) and (max-width: 1440px) {
  .sol {
    color: #0036AF;
    font-weight: 600;
    line-height: 60px;
    font-size: 41px !important;
  }
  .card h3 {
    font-size: 22px !important;
  }
  .pTitle {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 37px !important;
    font-weight: 400;
  }

  .card h3 {
    margin-top: 0;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 30px !important;
  }

  .card p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px #0000001F;
    padding: 20px;
    width: 365px;
    text-align: left;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 440px;
  }

  .rectangle {
    width: 100vw;
    height: 130px;
    background-color: #0036AF;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rectangle p {
    font-size: 45px;
    font-weight: 600;
    color: #ffffff;
    line-height: 70px !important;
  }

  .activities-slider {
    font-size: 43px;
    color: #0036AF;
    font-weight: 600;
    display: flex;
    justify-content: center;
    gap: 13px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .professional-equipment p {
    font-size: 24px;
    line-height: 38px !important;
    margin-bottom: 40px;
    font-weight: 400;
  }

  .professional-equipment h2 {
    color: #0036AF;
    font-size: 40px !important;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .equipment-card h3 {
    color: #0036AF;
    font-size: 21px !important;
    margin-bottom: 10px;
  }

  .equipment-card li {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 5px;
  }

  .rectangle-2 p {
    font-size: 29px;
    font-weight: 600;
    line-height: 56px !important;
    text-align: center;

  }

  .Location h2 {
    font-size: 40px !important;
    margin-bottom: 31px !important;
  }

  .Location h3 {
    font-size: 21px !important;
  }

  .Location p {
    font-size: 16px !important;
  }

  .location-card {

    padding: 20px 20px 20px 20px !important;
    
    gap: 10px;
  }

  .banner {
    height: 480px;
    background-size: cover;
    background-position: right center;
    width: 100%;
  }
}

@media(min-width: 768px) and (max-width: 1024px) {
  .banner {
    background-image: url('../../../../public/images/tablette-banner.png');
    height: 400px;
  }

  .sol {
    line-height: 50px;
    font-size: 36px !important;
  }

  .pTitle {
    font-size: 24px;
    line-height: 38px !important;
  }
  .cards{
    margin-bottom: 55px !important;
  }
  .card p {
    font-size: 14px;
  }
  .card h3 {
    font-size: 22px !important;
  }

  .rectangle p {
    font-size: 42px;
    line-height: 58px !important;
  }

  .activities-slider {
    font-size: 33px;
    color: #0036AF;
    font-weight: 600;
    display: flex;
    justify-content: center;
    gap: 14px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 45px;
  }

  .professional-equipment {
    padding: 27px !important;
    margin-bottom: 55px;
  }

  .professional-equipment h2 {
    font-size: 40px !important;
  }

  .professional-equipment p {
    font-size: 24px;
    line-height: 32px !important;
  }
  .rectangle-2{
    margin-bottom: 55px;
  }

  .rectangle-2 p {
    font-size: 30px;
    font-weight: 600;
    line-height: 52px !important;
  }
  .equipment-cards{
    flex-wrap: wrap;
  }
  .locHome {
    margin-bottom: 65px;
}
}

@media(max-width: 767px) and (min-width: 600px) {
  .banner {
    background-image: url('../../../../public/images/mobile-banner.png');
    height: 265px;
  }
}

@media(max-width: 600px) {
  .banner {
    background-image: url('../../../../public/images/mobile-banner.png');
    height: 230px;
  }
}
@media(max-width: 480px) {
  .banner {
    background-image: url('../../../../public/images/mobile2banner.png');
    height: 185px;
  }
}

@media(max-width: 767px) and (min-width: 480px) {

  .sol {
    line-height: 45px;
    font-size: 30px !important;
  }

  .pTitle {
    font-size: 20px;
    line-height: 31px !important;
  }

  .card {
    width: 430px;
  }

  .card p {
    font-size: 15px;
  }

  .card h3 {
    font-size: 24px !important;
  }

  .rectangle {
    height: 137px;
  }

  .rectangle p {
    font-size: 34px;
    line-height: 50px !important;
  }

  .activities-slider {
    font-size: 32px;
    gap: 15px;
    margin-bottom: 48px;
  }

  .professional-equipment {
    padding: 20px !important;
  }

  .professional-equipment h2 {
    font-size: 31px !important;
  }

  .professional-equipment p {
    font-size: 20px;
    line-height: 28px !important;
  }
  .equipment-cards{
    flex-wrap: wrap;
  }

  .equipment-card h3 {
    font-size: 19px !important;
  }

  .equipment-card li {
    font-size: 16px;
  }

  .rectangle-2 p {
    font-size: 23px;
    font-weight: 500;
    line-height: 40px !important;
  }
  
  .Location a {
    font-size: 14px;
    line-height: 20px !important;
  }
 
}

@media(max-width: 480px) {
  .sol {
    line-height: 35px;
    font-size: 28px !important;
    margin-top: 30px !important;
}
.pTitle {
  font-size: 16px;
  line-height: 22px !important;
}
.cards{
  margin-bottom: 40px !important;
}
.card p {
  font-size: 14px;
}
.card h3 {
  font-size: 22px !important;
}
.card {
  width: 320px;
}
.rectangle {
  height: 126px;
}
.rectangle p {
  font-size: 24px;
  line-height: 38px !important;
}
.activities-slider {
  font-size: 27px;
  gap: 8px;
  margin-bottom: 40px;
}
.professional-equipment{
margin-bottom: 40px;
}
.professional-equipment h2 {
  font-size: 28px !important;
}
.professional-equipment p {
  font-size: 16px;
  line-height: 24px !important;
}
.equipment-card h3 {
  font-size: 18px !important;
  line-height: 25px !important;
}
.equipment-card li {
  font-size: 15px;
  line-height: 22px;
}
.equipment-cards{
  flex-wrap: wrap;
}
.rectangle-2 p {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px !important;
}
.rectangle-2{
  margin-bottom: 30px;

}
.Location h2 {
  font-size: 26px !important;
  line-height: 36px !important;
}
.location-card {
  gap: 10px;
}
.location-card {
  padding: 10px 12px 10px 12px;
}
.locHome{
  margin-bottom: 40px !important;
}

}

@media(max-width: 430px) and (min-width: 420px){
  .equipment-card img {
    left:14%;
  }
  .equipment-cards{
    flex-wrap: wrap;
  }
}

@media(max-width: 420px) and (min-width: 320px){

  .equipment-card .eq2 {
    left: 0%;
}
  .equipment-cards{
    flex-wrap: wrap;
  }
}